import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-tab-overview"},[_c(VCard,[_c(VCardTitle,[_vm._v(" Details ")]),_c(VDivider),_c(VCardText,[_c(VList,{attrs:{"two-line":""}},[_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold body-1"},[_vm._v(" "+_vm._s(_vm.t('ID'))+" ")]),_c(VListItemSubtitle,{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm.data.id)+" ")])],1)],1),_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold body-1"},[_vm._v(" "+_vm._s(_vm.t('Title'))+" ")]),_c(VListItemSubtitle,{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm.data.title)+" ")])],1)],1),_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold body-1"},[_vm._v(" "+_vm._s(_vm.t('Sort'))+" ")]),_c(VListItemSubtitle,{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm.data.sort)+" ")])],1)],1),_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold body-1"},[_vm._v(" "+_vm._s(_vm.t('Image URL'))+" ")]),_c(VListItemSubtitle,{staticClass:"secondary--text"},[(_vm.data.thumbUrl)?_c('a',{attrs:{"href":_vm.data.thumbUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.data.thumbUrl)+" ")]):_c('span',{staticClass:"text--secondary"},[_vm._v("-")])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }