<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="data.image ? '' : 'primary'"
            :class="data.image ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img
              v-if="data.thumbUrl"
              :src="data.thumbUrl"
            ></v-img>
            <span
              v-else
              class="font-weight-semibold text-5xl"
            >{{ avatarText(data.title) }}</span>
          </v-avatar>

          <span class="mb-2">{{ data.title | textEllipsis(90) }}</span>
        </v-card-title>

        <v-card-text class="d-flex justify-center flex-wrap mt-2 pe-sm-0">
          <div class="d-flex align-center me-8 mb-4">
            <v-avatar
              size="40"
              rounded
              color="primary"
              class="v-avatar-light-bg primary--text me-3"
            >
              <v-icon
                color="primary"
                size="22"
              >
                {{ (videoCount > 0) ? icons.mdiCheck : icons.mdiClose }}
              </v-icon>
            </v-avatar>

            <div>
              <h3 class="text-xl font-weight-medium mb-n1">
                {{ kFormatter(videoCount) }}
              </h3>
              <span>{{ tc('Video', videoCount) }}</span>
            </div>
          </div>

          <!-- <div class="d-flex align-center mb-4 me-4">
            <v-avatar
              size="40"
              rounded
              color="primary"
              class="v-avatar-light-bg primary--text me-3"
            >
              <v-icon
                color="primary"
                size="22"
              >
                {{ icons.mdiBriefcaseVariantOutline }}
              </v-icon>
            </v-avatar>

            <div>
              <h3 class="text-xl font-weight-medium mb-n1">
                {{ kFormatter(data.projectDone) }}
              </h3>
              <span>Project Done</span>
            </div>
          </div> -->
        </v-card-text>

        <v-card-actions
          v-if="$can('update', 'Academy')"
          class="justify-center"
        >
          <v-btn
            color="primary"
            :to="{ name: 'academy-edit', params: { id: data.id } }"
          >
            {{ t('Edit') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- edit profile dialog data -->
      <academy-bio-edit
        v-if="$can('update', 'Academy')"
        :data="data"
        :handle-submit="handleUpdate"
        :is-bio-dialog-open.sync="isBioDialogOpen"
      ></academy-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline, no-unused-vars
import { mdiCheck, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle, mdiClose } from '@mdi/js'
import { avatarText, kFormatter } from '@core/utils/filter'
import { computed } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'

export default {
  components: {},
  props: {
    data: {
      type: Object,
      required: true,
    },
    handleUpdate: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const { t, tc } = useUtils()

    // const isBioDialogOpen = ref(false)

    return {
      avatarText,
      kFormatter,

      // isBioDialogOpen,
      icons: {
        mdiCheck,
        mdiClose,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },

      t,
      tc,

      videoCount: computed(() => (props.data?.videos || [])?.length || 0),
    }
  },
}
</script>
