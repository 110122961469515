<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <academy-bio-panel
          v-if="academy"
          :data="academy"
          :handle-update="updateData"
        ></academy-bio-panel>
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-tabs
          v-model="academyTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.title"
            :append="tab.append"
            :to="tab.to"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="academyTab"
          class="mt-5 pa-1"
        >
          <v-tab-item
            v-if="academy"
            value="overview"
          >
            <academy-tab-overview :data="academy"></academy-tab-overview>
          </v-tab-item>
          <v-tab-item
            v-if="academy"
            value="videos"
          >
            <academy-tab-videos
              :academy-id="academy.id"
              :items="academy.videos"
            ></academy-tab-videos>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline, no-unused-vars
import { onUnmounted, ref, provide } from '@vue/composition-api'
import { mdiAccountOutline, mdiFormatListBulleted } from '@mdi/js'
import { useRouter } from '@core/utils'

import useAcademyView from './useAcademyView'
import AcademyBioPanel from './academy-bio-panel/AcademyBioPanel.vue'
import AcademyTabOverview from './academy-tab-overview/AcademyTabOverview.vue'
import AcademyTabVideos from './academy-tab-videos/AcademyTabVideos.vue'

export default {
  components: {
    AcademyBioPanel,
    AcademyTabVideos,
    AcademyTabOverview,
  },
  setup() {
    const academyTab = ref(null)
    const { route } = useRouter()

    const {
      academy,
      loading,
      updating,

      errorMessages,

      init,
      destroy,

      updateData,
    } = useAcademyView(route.value.params.id)

    // Init
    init()

    // Destroy
    onUnmounted(() => {
      destroy()
    })

    const tabs = [
      {
        icon: mdiAccountOutline,
        title: 'Overview',
        append: true,
        to: '#overview',
      },
      {
        icon: mdiFormatListBulleted,
        title: 'Videos',
        append: true,
        to: '#videos',
      },
    ]

    return {
      tabs,
      academyTab,

      academy,
      loading,
      updating,
      updateData,

      errorMessages,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
