import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"user-bio-panel"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"pt-10"},[_c(VCardTitle,{staticClass:"justify-center flex-column"},[_c(VAvatar,{staticClass:"mb-4",class:_vm.data.image ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":_vm.data.image ? '' : 'primary',"size":"120","rounded":""}},[(_vm.data.thumbUrl)?_c(VImg,{attrs:{"src":_vm.data.thumbUrl}}):_c('span',{staticClass:"font-weight-semibold text-5xl"},[_vm._v(_vm._s(_vm.avatarText(_vm.data.title)))])],1),_c('span',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm._f("textEllipsis")(_vm.data.title,90)))])],1),_c(VCardText,{staticClass:"d-flex justify-center flex-wrap mt-2 pe-sm-0"},[_c('div',{staticClass:"d-flex align-center me-8 mb-4"},[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text me-3",attrs:{"size":"40","rounded":"","color":"primary"}},[_c(VIcon,{attrs:{"color":"primary","size":"22"}},[_vm._v(" "+_vm._s((_vm.videoCount > 0) ? _vm.icons.mdiCheck : _vm.icons.mdiClose)+" ")])],1),_c('div',[_c('h3',{staticClass:"text-xl font-weight-medium mb-n1"},[_vm._v(" "+_vm._s(_vm.kFormatter(_vm.videoCount))+" ")]),_c('span',[_vm._v(_vm._s(_vm.tc('Video', _vm.videoCount)))])])],1)]),(_vm.$can('update', 'Academy'))?_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{attrs:{"color":"primary","to":{ name: 'academy-edit', params: { id: _vm.data.id } }}},[_vm._v(" "+_vm._s(_vm.t('Edit'))+" ")])],1):_vm._e()],1),(_vm.$can('update', 'Academy'))?_c('academy-bio-edit',{attrs:{"data":_vm.data,"handle-submit":_vm.handleUpdate,"is-bio-dialog-open":_vm.isBioDialogOpen},on:{"update:isBioDialogOpen":function($event){_vm.isBioDialogOpen=$event},"update:is-bio-dialog-open":function($event){_vm.isBioDialogOpen=$event}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }