import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-tab-overview"},[_c(VCard,[_c(VCardTitle,[_vm._v(" Search & Filter ")]),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VRow,{attrs:{"align":"center","align-content":"center","justify":"space-between"}},[_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c('table-search',{attrs:{"dense":"","outlined":"","hide-details":"","full-width":""},model:{value:(_vm.options.q),callback:function ($$v) {_vm.$set(_vm.options, "q", $$v)},expression:"options.q"}})],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.items,"options":_vm.options,"loading":_vm.loading,"disable-sort":"","disable-pagination":"","hide-default-footer":"","search":_vm.options.q},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.duration",fn:function(ref){
var item = ref.item;
return [(item.duration)?_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(item.duration)+"s")]),_c('small',[_vm._v("("+_vm._s(_vm._f("parseMinutes")(item.duration))+")")])]):_vm._e()]}},{key:"item.thumbUrl",fn:function(ref){
var item = ref.item;
return [(item.thumbUrl)?_c('a',{attrs:{"href":item.thumbUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(item.thumbUrl)+" ")]):_c('span',{staticClass:"text--secondary"},[_vm._v("-")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","target":"blank","href":_vm.generateVimeoUrl(item.videoId)}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("View Video")])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }