<template>
  <div class="user-tab-overview">
    <v-card>
      <v-card-title>
        Search &amp; Filter
      </v-card-title>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-row
          align="center"
          align-content="center"
          justify="space-between"
        >
          <v-col
            cols="12"
            md="8"
          >
            <!-- search -->
            <table-search
              v-model="options.q"
              dense
              outlined
              hide-details
              full-width
            />
          </v-col>

          <!-- <v-spacer></v-spacer> -->
        </v-row>
      </v-card-text>

      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="items"
        :options.sync="options"
        :loading="loading"
        disable-sort
        disable-pagination
        hide-default-footer
        :search="options.q"
      >
        <!-- name -->
        <template #[`item.duration`]="{item}">
          <div
            v-if="item.duration"
            class="d-flex flex-column"
          >
            <span>{{ item.duration }}s</span>
            <small>({{ item.duration | parseMinutes }})</small>
          </div>
        </template>

        <template #[`item.thumbUrl`]="{item}">
          <a
            v-if="item.thumbUrl"
            :href="item.thumbUrl"
            target="_blank"
          >
            {{ item.thumbUrl }}
          </a>
          <span
            v-else
            class="text--secondary"
          >-</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                target="blank"
                :href="generateVimeoUrl(item.videoId)"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>View Video</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiFilePdf, mdiEyeOutline } from '@mdi/js'
// eslint-disable-next-line object-curly-newline
import { ref, computed, onMounted, onUnmounted } from '@vue/composition-api'
import useVideoList from './useVideoList'

export default {
  props: {
    academyId: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
      defualt: () => [],
    },
  },
  setup(props) {
    const loading = ref(false)
    const totalItemListTable = computed(() => props.items.length || 0)

    const {
      itemListTable,
      tableColumns,
      options,

      init,
      destroy,
    } = useVideoList(props.academyId)

    onMounted(() => {
      init()
    })

    onUnmounted(() => {
      destroy()
    })

    const generateVimeoUrl = videoId => `https://player.vimeo.com/video/${videoId}`

    return {
      icons: {
        mdiFilePdf,
        mdiEyeOutline,
        mdiDotsVertical,
      },

      itemListTable,
      tableColumns,
      options,

      totalItemListTable,
      loading,

      // parentFilter,
      // itemTotalLocal,

      generateVimeoUrl,
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
